<template>
  <button class="custom-animation rounded-2xl bg-primary-gradient px-5 py-3">
    <slot></slot>
  </button>
</template>

<style scoped>
.custom-animation {
  color: white;
  background: linear-gradient(260.37deg, #b181f7 4.07%, #0442e3 124.78%);
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.custom-animation::after {
  background: #fff;
  border: 0 solid #000;
  content: "";
  height: 155px;
  position: absolute;
  opacity: 0.2;
  left: -75px;
  top: -50px;
  transform: rotate(35deg);
  width: 2rem;
  transition: all 1s cubic-bezier(0.175, 0.52, 0.165, 1);
  animation: animate-effect 4s ease-in-out 2s infinite;
}

@keyframes animate-effect {
  0% {
    transform: rotate(35deg);
    left: -75px;
  }

  70% {
    transform: rotate(35deg);
    left: -75px;
  }

  100% {
    transform: rotate(40deg);
    left: 120%;
  }
}
</style>
