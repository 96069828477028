/* eslint-disable max-len */
export const FEATURES_INFO = [
  {
    title: "Adaptive Learning",
    description: "Instant feedback on user performance, rewards and progressive challenges that inspire learners to improve their skills.",
    icon: "notebook",
    color: "#8C46F3",
    className: "home-purple",
    iconClass: "descendant:text-home-purple",
  },
  {
    title: "Gamification",
    description: "We use generative AI tools to customize learning materials to prevent overwhelming learners while providing ideal challenges.",
    icon: "game-pad",
    color: "#FD62A4",
    className: "home-red",
    iconClass: "descendant:text-home-red",
  },
  {
    title: "Personalized Feedback",
    description: "Using generative AI, we offer personalized feedback that highlights strengths and areas to focus on.",
    icon: "feedback",
    color: "#58E0B0",
    className: "home-green",
    iconClass: "descendant:text-home-green",
  },
] as const;

export const INSTRUCTORS_INFO = [
  {
    icon: "recommend",
    title: "Content Suggestion",
    text: "Gain insights from learner's challenges to generate content",
  },
  {
    icon: "edit",
    title: "Educate Your Own Way",
    text: "Share knowledge with your own flair and ignite a love for learning",
  },
  {
    icon: "edit",
    title: "Inspire Learners",
    text: "Fuel the desire for knowledge and growth",
  },
] as const;

export const LEARNERS_INFO = [
  {
    icon: "recommend",
    title: "Immediate Feedback",
    text: "You would be able to get reviews on content based on viewer's assessment",
  },
  {
    icon: "edit",
    title: "Personalized & Adaptive",
    text: "Learnerverse creates a customized experienced for each individual learner.",
  },
] as const;

export const FAQ = [
  {
    question: "What is Learnerverse?",
    answer: {
      text: "Learnerverse is a cutting-edge online learning platform that leverages artificial intelligence (AI) to enhance, customize, and gamify the user learning journey. Learnerverse helps users achieve their learning goals through continuous feedback and improvement.",
      list: [],
    },
  },
  {
    question: "How do I teach on Learnerverse?",
    answer: {
      text: "Learnerverse allows you to create course content in various formats, such as text, audio, or media. You can also use our tools to help you design and deliver your course effectively.",
      list: [],
    },
  },
  {
    question: "Who can teach on Learnerverse?",
    answer: {
      text: "Learnerverse welcomes anyone who has valuable knowledge to share to become a teacher on our platform. We believe that we can all teach and learn from each other. However, we also ensure that our premium courses meet high standards before publication.",
      list: [],
    },
  },
  {
    question: "What are the incentives for course creators?",
    answer: {
      text: "Course creators have two ways to earn income:",
      list: [
        "They can create premium courses and receive a generous percentage of the purchase fee.",
        "They can also earn a share of the ad revenue, depending on how much their content attracts and retains users.",
      ],
    },
  },
  {
    question: "Do I need a subscription to learn?",
    answer: {
      text: "No. You can learn without a subscription, as all non-premium content is free for everyone. However, you need to register to use some of the AI tools, and you need a subscription to unlock all the tools and features.",
      list: [],
    },
  },
  {
    question: "What are the benefits of a subscription?",
    answer: {
      text: "As a subscribed user, you can benefit from a distraction-free learning environment without ads and Complete access to the AI tools, assessment reports, and personalized suggestions.",
      list: [],
    },
  },
  {
    question: "How is Learnerverse different from other learning platforms?",
    answer: {
      text: "Learnerverse distinguishes itself by:",
      list: [
        "Emphasizing continuous improvement rather than grades.",
        "Providing innovative tools that enhance the user learning experience.",
        "Offering assessments to measure user understanding and suggest areas of improvement.",
        "Delivering detailed reports on progress based on cumulative assessment results.",
      ],
    },
  },
] as const;
